// import React from 'react'
// import { render } from 'react-dom'
// import { Provider } from 'react-redux'
// import { createStore } from 'redux'
// import jamescodes from './reducers'
// import App from './components/App'


// let store = createStore(jamescodes)

// render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// )

import React from "react"
// import Component from "./components/component"
import Background from "./components/background"


let initialise = () => {

  let home      = Background()
  let rootEl    = document.getElementById('root')
  let links     = rootEl.getElementsByTagName('a')

  // Create the background
  document.body.appendChild(home)
  

  for (var i = links.length - 1; i >= 0; i--) {

    let slug       = slugify(links[i].text)
    let background = Background(slug)

    background.dataset.link = slug;
    background.classList.add('hover');
    document.body.appendChild(background)

    links[i].addEventListener('mouseover', setBackground)
    links[i].addEventListener('mouseout', resetBackground)
  }
}

let setBackground = ( event ) => {

  let slug       = slugify(event.target.text)
  let background = document.querySelector(`canvas[data-link="${slug}"]`);

  background.style.opacity = 0.8

}

let resetBackground = ( event ) => {

  let slug       = slugify(event.target.text)
  let background = document.querySelector(`canvas[data-link="${slug}"]`);

  background.removeAttribute('style')

}

let slugify = (text) => {
  return text.toString().toLowerCase().replace(/\s+/g, '-')
}



initialise();