import Trianglify from "trianglify"

let Background = ( type )=> {

  let colors = null;

  switch(type){
    case 'jamescodes' :
      colors = ["#125688", "#b6daf5", "#125688"]
    break;
    case 'technical-lead' :
      colors = ["#d89038", "#f8ebdc", "#e2ab69"]
    break;
    case 'vj' :
      colors = ["#00ffAA", "#c4ffeb", "#00764e"]
    break;
    default :
      colors = ['#000000', '#333333', '#000000']
    break;
  }

  let triangles = Trianglify({
      width: window.innerWidth,
      height: window.innerHeight,
      cell_size: 50,
      x_colors: colors
  });

  return triangles.canvas();

}

export default Background